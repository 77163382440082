import React from 'react';

// import { Container } from './styles';

function CircleProgress({ score }) {
    return (
        <div className={`progress-circle-bar progress-${score}`}>
            <div className="progress__number">{score}</div>
        </div>
    );
}

export default CircleProgress;