import React from 'react';

import './style.css'

function FloatButton({ location, action }) {
    return (
        <div id="float-button" className={location ? `float-${location}-location` : ''} onClick={(e) => action()}>
            <div className="float-button-container d-flex align-items-center justify-content-center">
                <span className="material-icons">add</span>
            </div>
        </div>
    );
}

export default FloatButton;