import React from 'react';

import './main.css';

function ReportCard({ children, title, customClass }) {
    return (
        <div className={`report-card h-100 min-height-100 ${customClass}`}>
            {
                title
                    ?
                    <div className="report-card-title">
                        <span className="fw-500">{title}</span>
                    </div>
                    :
                    null
            }
            <div className="report-card-body">
                {children}
            </div>
        </div>
    );
}

export default ReportCard;