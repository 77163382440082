import React from 'react';
import CreatableSelect from 'react-select/creatable';


import Select from 'react-select'

function ServiceEdition({ associatedQuestions, setAssociatedQuestions, setRemovedQuestions, removedQuestions, allQuestions, serviceName, setServiceName }) {

    function handleChange(data, action) {
        if (action.removedValue) {
            setAssociatedQuestions(data);
            if (!isNaN(action.removedValue.value)) {
                setRemovedQuestions([...removedQuestions, { value: action.removedValue.value, label: action.removedValue.label }]);
            }
        } else if (action.action === 'select-option' || action.action === 'create-option') {
            setAssociatedQuestions(data);
        }
    }

    return (
        <form action="#" id="service-edition-form" autoComplete="false">
            <div className="row">
                <div className="col-md-4">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="client_name">Nome do serviço</label>
                        <input value={serviceName} onChange={(e) => setServiceName(e.target.value)} type="text" name="name" id="service_name" />
                    </div>
                </div>
            </div>
            <div className="divisor row my-4">
                <div className="col-md-12 d-flex align-items-center">
                    <span className="mr-3">Questões</span>
                    <hr className="w-100 flex-1" />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">

                    <div className="row">
                        <div className="col-md-12">
                            {
                                allQuestions.length
                                    ?
                                    <CreatableSelect className="mt-3" isMulti={true} placeholder="Selecione as questões"
                                        options={allQuestions.map(question => { return { value: question.id, label: question.content ?? question.label } })}
                                        noOptionsMessage={() => "Nenhuma opção encontrada"}
                                        value={associatedQuestions.map(question => { return { value: question.id ?? question.value, label: question.content ?? question.label } })}
                                        onChange={handleChange}
                                    />
                                    :
                                    <CreatableSelect className="mt-3" isMulti={true} placeholder="Selecione as questões"
                                        options={[]}
                                        noOptionsMessage={() => "Nenhuma opção encontrada"}
                                        defaultValue={[]}
                                        onChange={handleChange}
                                    />}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ServiceEdition;